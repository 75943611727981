import { useEffect, useState } from 'react'
import styles from './Sidebar.module.sass'
import { Link, NavLink, useLocation } from 'react-router-dom'
import cn from 'classnames'
import Icon from '../Icon'
import Theme from '../Theme'
import Dropdown from './Dropdown'
import Image from '../Image'
// import { useAuth } from '../../AuthContext'
import { Amplify } from 'aws-amplify'
import { generateClient } from 'aws-amplify/api'
import { getUser } from '../../graphql/queries'
import { useSelector } from 'react-redux';
import amplifyConfig from '../../amplifyConfig'
const Sidebar = ({ className, onClose }) => {
  // const [visibleHelp, setVisibleHelp] = useState(false)
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  // const { userDetails, isAuthenticated } = useAuth()
  const isAuthenticated = useSelector(state => state.auth.isAutheticated);
  const userDetails = useSelector(state => state.auth.userDetails);

  // const [userSubscriptions, setUserSubscriptions] = useState([])
  const subscriptions = useSelector(state => state.userSubs?.userSubscriptions)
  const [subDetails, setSubDetails] = useState([]);
  useEffect(() => {
    let screenWidth = window.innerWidth;
    function updateWindowWidth() {
      screenWidth = window.innerWidth;
      if (screenWidth > 1259 || screenWidth < 767) {
        setVisible(false);
      }
    }
    window.addEventListener('resize', updateWindowWidth);
  }, [])

  Amplify.configure(amplifyConfig)
  const client = generateClient()

  useEffect(() => {
    const getUserSubscriptions = async () => {
      try {
        if (subscriptions?.length > 0) {
          const subDetailsData = []; // Temporary array to accumulate fetched data

          // Fetch data for each subscription
          for (let i = 0; i < subscriptions?.length; i++) {
            const data = await client.graphql({
              query: getUser,
              variables: {
                id: subscriptions[i].userId
              }
            });
            subDetailsData.push(data);
          }

          // Update state once after fetching all data
          setSubDetails(subDetailsData);
        }
      } catch (error) {
        console.error('Error fetching user subscriptions:', error);
      }
    };

    if (isAuthenticated && subscriptions?.length > 0) {
      getUserSubscriptions();
    }
  }, [subscriptions, isAuthenticated]);
  

  // replace this part in future DISCLAIMER
  const navigation = [
    {
      title: 'Home',
      icon: 'home',
      url: '/'
    },
    {
      title: 'My Profile',
      icon: 'userav',
      url: `/${userDetails?.username}`
    },
    {
      title: 'Subscriptions',
      slug: 'products',
      icon: 'diamond',

      dropdown:
        subscriptions?.length > 0
          ? subDetails.map((sub, index) => {
            return {
              title: sub?.data?.getUser?.username,
              avatar: sub?.data?.getUser?.avatar ? `${process.env.REACT_APP_S3_PUBLIC}${sub.data.getUser.avatar}` : `${process.env.REACT_APP_DEFAULT_IMAGE}`
            }
          })
          : [{ title: 'No Subscriptions yet' }]
    },
    {
      title: 'Bug Report',
      icon: 'bug',
      url: `my/bug-report`
    },
   
  ]

  return (
    <>
      <div
        className={cn(styles.sidebar, className, {
          [styles.active]: visible
        })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name='close' size='24' /> 
        </button>
        <Link className={styles.logo} to='/' onClick={onClose}>
          <Image
            className={styles.pic}
            src='/images/logo_light.png'
            srcDark='/images/logo_dark.png'
            alt='Core'
          />
        </Link>
        <div className={styles.menu}>
          {navigation.map((x, index) => {
            // Check if the user is a creator and authenticated
            const isCreator = userDetails?.isCreator && isAuthenticated
            const isSubscriber = isAuthenticated && !isCreator // Check if the user is authenticated and not a creator

            // Check if the title is "Home", "Subscribers", or the user is a creator
            if (
              x.title === 'Home' ||
              isCreator ||
              (isSubscriber && x.title === 'Subscriptions')
            ) {
              return x.url ? (
                <NavLink
                  className={cn(styles.item, {
                    [styles.active]: pathname === x.url
                  })}
                  to={x.url}
                  key={index}
                  onClick={onClose}
                >
                  <Icon name={x.icon} size='24' />
                  {x.title}
                </NavLink>
              ) : (
                <div key={index}>
                  <Dropdown
                    className={styles.dropdown}
                    visibleSidebar={visible}
                    setValue={setVisible}
                    subscribersLength={subscriptions?.length}
                    item={x}
                    onClose={onClose}
                  />
                </div>
              )
            }
          })}
        </div>
        <div className={styles.help}>
          <div className={cn(styles.item)}>
        <Icon name='help' size='24' />
          <p>contact@ascnd.tv</p>
          </div>
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name='arrow-right' size='24' />
          <Icon name='close' size='24' />
        </button>
        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  )
}


export default Sidebar
