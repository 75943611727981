const awsmobile = {
 "aws_project_region": "eu-north-1",
      "aws_appsync_graphqlEndpoint": "https://x77bvtroefbepdv7ghqp3wu7hm.appsync-api.eu-north-1.amazonaws.com/graphql",
      "aws_appsync_region": "eu-north-1",
      "aws_appsync_authenticationType": "API_KEY",
      "aws_appsync_apiKey": "da2-umblad2zg5elbjttkkbh5ghes4",
      "aws_cognito_identity_pool_id": "eu-north-1:41a39502-ca7d-4989-b607-2a61eb1a1790",
      "aws_cognito_region": "eu-north-1",
      "aws_user_pools_id": "eu-north-1_DdtWmkVUA",
      "aws_user_pools_web_client_id": "21vi2bmrl3rqchla561sj4v18k",
      "oauth": {
          "domain": "onlygamers24762aed-24762aed-staging.auth.eu-north-1.amazoncognito.com",
          "scope": [
              "phone",
              "email",
              "openid",
              "profile",
              "aws.cognito.signin.user.admin"
          ],                  "redirectSignIn":"https://dev.ascnd.tv/my/google_sign_in/,http://localhost:3000/my/google_sign_in/,https://www.ascnd.tv/my/google_sign_in/,https://www.ascnd.tv/my/google_sign_in/",
          "redirectSignOut": "https://dev.ascnd.tv/,http://localhost:3000/,https://www.ascnd.tv/",
          "responseType": "code"
      },
      "federationTarget": "COGNITO_USER_POOLS",
      "aws_cognito_username_attributes": [
          "EMAIL"
      ],
      "aws_cognito_social_providers": [
          "GOOGLE"
      ],
      "aws_cognito_signup_attributes": [
          "EMAIL"
      ],
      "aws_cognito_mfa_configuration": "OFF",
      "aws_cognito_mfa_types": [
          "SMS"
      ],
      "aws_cognito_password_protection_settings": {
          "passwordPolicyMinLength": 8,
          "passwordPolicyCharacters": []
      },
      "aws_cognito_verification_mechanisms": [
          "EMAIL"
      ],
      "aws_user_files_s3_bucket": "onlygamers-storage164905-staging",
      "aws_user_files_s3_bucket_region": "eu-north-1",
      "aws_mobile_analytics_app_id": "a7f3ef9dd09e4054b59ce06e0f207a46",
      "aws_mobile_analytics_app_region": "eu-central-1",
      "Analytics": {
          "AWSPinpoint": {
              "appId": "a7f3ef9dd09e4054b59ce06e0f207a46",
              "region": "eu-central-1"
          }
      }
};
export default awsmobile;
