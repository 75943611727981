import { useSelector } from 'react-redux';
import { useRef, useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api'
import { createSubscriberRequest } from '../../graphql/mutations';
import { Amplify } from 'aws-amplify';
import styles from './ProfilePage.module.sass'
import amplifyConfig from '../../amplifyConfig';
const AddRequest = ({ isVisible, onClose, triggerParent }) => {
    const cardRef = useRef(null);
    const tipRef = useRef(null);
    const userDetails = useSelector(state => state?.auth?.userDetails);
    const userData = useSelector((state) => state?.user?.userData);
    const [cardHeight, setCardHeight] = useState(0);
    const userAvatar = useSelector(state => state?.auth?.userDetails?.avatar);
    const username = useSelector(state => state?.auth?.userDetails?.username);
    const [cardDetails, setCardDetails] = useState({
        title: "",
        description: "",
    })
    
    const handleChange = (event, identifier) => {
        setCardDetails((prev) => {
            return {
                ...prev,
                [identifier]: event.target.value
            }
        })
    }
    const handleAddRequest = async () => {
        if(tipRef.current && tipRef.current.value.length > 0){
            try {
                const donationAmount = Number(tipRef.current.value);
                const options = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        creatorUserId: userData[0].id,
                        buyerUserId: userDetails.id,
                        title: cardDetails.title,
                        content: cardDetails.description,
                        success_url: "https://www.ascnd.tv/",
                        cancel_url: "https://www.ascnd.tv/",
                        donationAmount,
                    })  
                };
            
                const response = await fetch(process.env.REACT_APP_DONATION_CHECKOUT, options);
                const data = await response.json();
                window.open(data.url, "_blank");
            } catch (err) {
              
            }
        }
        const addrequest = async () => {
            Amplify.configure(amplifyConfig)
            const client = generateClient()
            try {
                const res = await client.graphql({
                    query: createSubscriberRequest,
                    variables: {
                        input: {
                            user_id: userDetails.id,
                            creator_id: userData[0].id,
                            title: cardDetails.title,
                            content: cardDetails.description,
                            status: true,
                        }
                    }
                })
              

            } catch (error) {
                console.error(error)
            } finally {
                onClose();
                triggerParent();
                setCardDetails({
                    title: "",
                    description: ""
                })
            }
        }
        addrequest()
    }
    useEffect(() => {
        // Update card height when isVisible changes
        if (isVisible) {
            setCardHeight(cardRef.current.scrollHeight);
        } else {
            setCardHeight(0);
        }
        window.addEventListener('resize', () => {
         setCardHeight(cardRef?.current?.scrollHeight);   
        })
        return () => {
            window.removeEventListener('resize', () => {
                setCardHeight(cardRef?.current?.scrollHeight);   
            })
        }
    }, [isVisible]);

    const outerStyle = {
        height: isVisible ? `${cardHeight}px` : '0',
        overflow: 'hidden',
    };

    return (
        <div className={`card-outer mb-6`} style={outerStyle}>
            <div ref={cardRef} className={`requestCard rounded-[12px] ${styles.requestCard} p-6 w-full`}>
                <div className="requestCard__body">
                    <header className="cardBody__header flex items-center gap-4">
                        <div className='cardBody__avatar max-w-[50px] max-h-[50px]'>
                            <img className='w-full h-[50px] object-cover rounded-full' src={`${process.env.REACT_APP_S3_PUBLIC}${userAvatar}`} alt='Avatar' />
                        </div>
                        <div className='cardBody__info'>
                            <p className="card__name text-lg mb-1">
                                {username}
                            </p>
                            <p className="text-[#6F767E] text-[13px]">
                                Add a request
                            </p>
                        </div>
                    </header>
                    <div className="requestDetails mt-5 flex flex-col">
                        <input value={cardDetails.title} onChange={(e) => handleChange(e, 'title')} placeholder='Add a title' type='text' className='mb-[10px] text-sm bg-transparent outline-none border-none'></input>
                        <textarea value={cardDetails.description} onChange={(e) => handleChange(e, 'description')} placeholder='Add a description' type='text' className='mb-[10px] text-sm resize-none bg-transparent outline-none border-none'></textarea>
                    </div>
                </div>
                <footer className='requestCard__footer flex-col gap-6 md:h-12 flex md:flex-row md:items-center'>
                    <input
                        ref={tipRef}
                        onChange={(e) => handleChange(e, 'tip')}
                        placeholder='Add a tip (optional)'
                        type='text'
                        pattern="[1-9]\d*"
                        className='flex-grow text-sm bg-transparent outline-none border-none'
                        onKeyDown={(e) => {
                            if (!(e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Tab' || e.key === 'ArrowLeft' || e.key === 'ArrowRight' || e.key === 'Home' || e.key === 'End') && isNaN(parseInt(e.key))) {
                                e.preventDefault();
                            }
                        }}
                    />
                    <button onClick={handleAddRequest} className={`stripe-button self-end md:h-full h-12 w-[130px] overflow-hidden whitespace-nowrap rounded-xl px-5 ${tipRef?.current?.value?.length > 0 ? "bg-[#594BB9] hover:bg-[#5a4bb994] active" : " bg-[#2A85FF] hover:bg-[#0069f6]"}`}>
                        <span>Add request</span>
                        <span>Connect with stripe</span>
                    </button>
                </footer>
            </div>
        </div>
    );
};

export { AddRequest };
