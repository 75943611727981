import styles from './TipModal.module.sass'
import cn from 'classnames'
import TextInput from '../../TextInput'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
const TipModal = ({item}) => {
    const creatorUserId = useSelector((state) => state.user.userData[0].id);
    const buyerUserId = useSelector((state) => state.auth.userDetails.id);
    const [price, setPrice] = useState(0);
    const [loading, setLoading] = useState(false);
    const handleConnectToStripe = async () => {
      const options = {
        method: 'POST',
        body: JSON.stringify({
          creatorUserId,
          buyerUserId,
          requestId: item.id,
          success_url: "https://www.ascnd.tv/",
          cancel_url: "https://www.ascnd.tv/",
          donationAmount: price
        } ),
        headers: {
          'Content-Type': 'application/json',
        },
      }
      try{
        setLoading(true);
        const response = await fetch(process.env.REACT_APP_DONATION_CHECKOUT,options)
        const data = await response.json()
        window.open(data.url, "_self");
      }catch(err){
      
      }finally{
        setLoading(false);
      }
    }
    
  return (
    <>
      <div className={styles.details}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={cn('title-green', styles.title)}>Tip</div>

            <div className={styles.price}>
              <TextInput
                className={styles.field}
                label=''
                name='title'
                type='text'
                currency={'$'}
                 value={price}
                onChange={event => {
                  try {
                    let value = Number(event.target.value)
                    setPrice(value)
                  } catch (error) {
                  }
                }}
                required
              />
            </div>
            <div className={styles.btns}>
              <button
                style={{ backgroundColor: 'rgba(89, 75, 185, 1)' }}
                className={cn('button', styles.button)}
                  onClick={handleConnectToStripe}
              >
                Connect to Stripe
              </button>
            </div>

            <div className={styles.terms}>
              By continuing to Stripe, you agree to
              <div>
                Our{' '}
                <Link to='/terms' target='_blank'>
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link to='/contract' target='_blank'>
                  Creator Contract
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TipModal
