import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import styles from "./CreatorModal.module.sass";
export default function ConnectStripe() {
  const userId = useSelector((state) => state.auth.userDetails.id);
  const [loading, setLoading] = useState(false);
  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState("");
  if (textRef1.current) textRef1.current.style.opacity = 0;
  if (textRef2.current) textRef2.current.style.opacity = 0;
  if (textRef3.current) textRef3.current.style.opacity = 0;

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const handleLoading = () => {
    if (!selectedCountry) {
      alert("Please select your country.");
      return;
    }
    setLoading(true);
    try {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, selectedCountry }),
      };
      fetch(
        process.env.REACT_APP_ONBOARD_CREATOR,
        options
      )
        .then((response) => response.json())
        .then((data) => {
          setTimeout(() => {
            window.open(data.url, "_self");
          }, 1000);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } catch (error) {
      console.error("Error in handleLoading function:", error);
    }
    const timeOutId1 = setTimeout(() => {
      if (textRef1.current) textRef1.current.style.opacity = 1;
    }, 0);
    const timeOutId2 = setTimeout(() => {
      if (textRef1.current) textRef1.current.style.opacity = 0;
      if (textRef2.current) textRef2.current.style.opacity = 1;
    }, 2500);
    const timeOutId3 = setTimeout(() => {
      if (textRef2.current) textRef2.current.style.opacity = 0;
      if (textRef3.current) textRef3.current.style.opacity = 1;
    }, 5000);
    const timeOutId4 = setTimeout(() => {
      if (textRef3.current) textRef3.current.style.opacity = 0;
      if (textRef1.current) textRef1.current.style.opacity = 1;
      clearTimeout(timeOutId1);
      clearTimeout(timeOutId2);
      clearTimeout(timeOutId3);
      clearTimeout(timeOutId4);
      setLoading(false);
    }, 4500);
  };

  return (
    <div className="modalBody__container flex flex-col gap-6">
      <header className="modalBody__header flex items-center">
        <div className="left flex items-center gap-4">
          <span className="box block w-4 h-8 rounded bg-[#CABDFF]"></span>
          <p className={`font-semibold ${styles.subText} text-xl`}>
            Connect Stripe to Ascnd
          </p>
        </div>
      </header>
      <div className={`p-6 ${styles.body} rounded-lg flex flex-col gap-3`}>
        <p className="text-center whitespace-break-spaces cursor-pointer text-[#6F767E] font-semibold text-sm">
          By continuing with Stripe, you're linking your Stripe account to our
          platform via Stripe Connect, enabling us to seamlessly set up
          subscription services and process donations on your behalf. This
          connection ensures secure transactions and timely payouts directly to
          your account.{" "}
        </p>
        <select
          value={selectedCountry}
          onChange={handleCountryChange}
          className="w-full h-[48px] px-5 text-[15px] rounded-xl bg-white border border-gray-300 focus:outline-none focus:border-blue-500"
          style={{ color: "black" }}
        >
          <option value="">Select your country</option>
          {/* Loop through the array of countries and create an option for each */}
          {[
            { country: "Australia", code: "AU" },
            { country: "Greece", code: "GR" },
            { country: "Norway", code: "NO" },
            { country: "Austria", code: "AT" },
            { country: "Hong Kong", code: "HK" },
            { country: "Poland", code: "PL" },
            { country: "Belgium", code: "BE" },
            { country: "Hungary", code: "HU" },
            { country: "Portugal", code: "PT" },
            { country: "Bulgaria", code: "BG" },
            { country: "Ireland", code: "IE" },
            { country: "Romania", code: "RO" },
            { country: "Canada", code: "CA" },
            { country: "Italy", code: "IT" },
            { country: "Singapore", code: "SG" },
            { country: "Croatia", code: "HR" },
            { country: "Japan", code: "JP" },
            { country: "Slovakia", code: "SK" },
            { country: "Cyprus", code: "CY" },
            { country: "Latvia", code: "LV" },
            { country: "Slovenia", code: "SI" },
            { country: "Czech Republic", code: "CZ" },
            { country: "Liechtenstein", code: "LI" },
            { country: "Spain", code: "ES" },
            { country: "Denmark", code: "DK" },
            { country: "Lithuania", code: "LT" },
            { country: "Sweden", code: "SE" },
            { country: "Estonia", code: "EE" },
            { country: "Luxembourg", code: "LU" },
            { country: "Switzerland", code: "CH" },
            { country: "Finland", code: "FI" },
            { country: "Malta", code: "MT" },
            { country: "Thailand", code: "TH" },
            { country: "France", code: "FR" },
            { country: "Mexico ", code: "MX" },
            { country: "United Arab Emirates", code: "AE" },
            { country: "Germany", code: "DE" },
            { country: "Netherlands", code: "NL" },
            { country: "United Kingdom", code: "GB" },
            { country: "Gibraltar", code: "GI" },
            { country: "New Zealand", code: "NZ" },
            { country: "United States", code: "US" },
          ].map(({ country, code }) => (
            <option key={code} value={code} style={{ color: "black" }}>
              {country}
            </option>
          ))}
        </select>
        <button
          disabled={loading}
          onClick={handleLoading}
          className="w-full relative transition-all duration-300 hover:scale-105 active:scale-100 h-[48px] text-white font-semibold text-[15px] bg-gradient-to-r px-5 rounded-xl from-[#5B4BB9] to-[#60A8F2]"
        >
          <span
            className={`transition-all duration-300 ${
              loading ? "opacity-0 pointer-events-none" : ""
            }`}
          >
            Continue with Stripe
          </span>
          <span
            ref={textRef1}
            className="absolute transition-all duration-700 ease-in-out text-2"
          >
            We are creating your account 😊
          </span>
          <span
            ref={textRef2}
            className="absolute transition-all duration-700 ease-in-out text-2"
          >
            Give us a moment... 😎
          </span>
          <span
            ref={textRef3}
            className="absolute transition-all duration-700 ease-in-out text-2"
          >
            Your account is getting ready 💸
          </span>
        </button>
      </div>
    </div>
  );
}
